.projects {
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%,  rgba(0,212,255,1) 100%);
    padding: 40px 20px;
    text-align: center;
  }
  
.projects h1 {
    font-size: 36px;
    font-weight: bold;
    color: #bfb9b9;
    margin: 0;
    text-align: justify;
  }
  
  .projects h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .project-info h2{
    text-align: center;
  }
  
  .projects button {
    font-size: 24px;
    background-color: #333;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .projects button:hover {
    background-color: #fff;
    color: #333;
  }
  
  .projects ul {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(33%, 1fr));
    grid-gap: 20px;
  }
  
  .projects  li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 600px;
    padding: 20px;
    text-align: left;
    transition: transform 0.3s ease;
  }
  
  .project-list  li:hover {
    transform: translateY(-5px);
  }
  
  .project-list  li h2 {
    font-size: 24px;
    margin-top: 0;
  }
  
  .project-list   li p {
    margin: 10px 0;
  }
  
  .project-list  li img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
  }
  
  .project-list  li img:hover {
    transform: scale(1.05);
  }