.footer {
  background-color:rgba(2,0,36,1); /* #333;*/
  color: #fff;
  padding: 20px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

p {
  margin: 0;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.social-links a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
}

.social-links a:hover {
  text-decoration: underline;
}