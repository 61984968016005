.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #02C6DE;
    padding: 1rem;
    justify-content: center; 
    max-width: 100%;
  }
  
  .Navbar ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .Navbar li {
    margin-right: 1rem;
  }
  
  .Navbar li:last-child {
    margin-right: 0;
  }
  
  .Navbar li a {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .Navbar li a:hover {
    background-color: #fff;
    color: #000;
    transition: background-color 0.3s ease;
  }