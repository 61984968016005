.popup-overlay-project {
    position: fixed; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto; /* se agrega overflow */
    padding: 1rem;
    overflow-y: auto;

    flex-direction: column;
    max-height: 85%; /* Establecemos la altura máxima */
    overflow-y: auto; /* Aparece la barra de desplazamiento solo si se excede el contenido */
    justify-content: space-between;
  }
  
  .popup-content-project {
    background-color: white;
    width: 85%;
    max-width: 85%;
    display: flex;
    flex-direction: column;
    overflow: auto; /* se agrega overflow */
    padding: 1rem;
    overflow-y: auto;

  }
  
  .popup-header-project {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    font-weight: bold;
    align-self: center;
    width: 100%;
    text-align: center;
    
    color: black;
    position: sticky;
    top: 0%;
    z-index: 1;
    background-color: white;
  }
  
  .popup-header-project a {
    color: black;
  }
  .popup-header-project-title{
    margin: 0;
    color: black;
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    text-align: center;}
    
  .popup-header-project h1 {
    margin: 0;
    color: black;
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }
  #popup-header-project h1 {
    margin: 0;
    color: black;
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }

  #popup-header-project {
    margin: 0;
    color: black;
    font-size: 36px;
    font-weight: bold;
    color: #bfb9b9;
    margin: 0;
    text-align: center;
  }
  
  .popup-left-project {
    flex: 0 0 55%;
    font-weight: bold;
    width: 55%; /* se agrega la propiedad width */
  }
  
  .popup-right-project {
    flex: 0 0 40%;
    width: 40%; /* se agrega la propiedad width */
    padding: 10px 10px;
  }
  
  .popup-right-project p {
    text-align: justify;
  }
  
  .popup-body-project {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
    width: 100%; /* se agrega la propiedad width */
   
  }
  
  .popup-body-project img {
    max-width: 100%;
  }
  
  .popup-body-footer-project {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
    align-self: center;

  }
  
  .popup-body-footer-project h1 {
    color: black;
    margin: 0;
  }
  


  .github {
    display: inline-block;
    background-color: #6f42c1;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.2s ease-in-out;
  }
  
.github:hover {
    background-color: #5e3bb8;
  }

.production{
  display: inline-block;
  background: rgb(34,86,195);
background: linear-gradient(0deg, rgba(34,86,195,1) 0%, rgba(69,45,253,1) 100%);
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s ease-in-out;  
}
.production:hover {
  background-color: #5e3bb8;
}