.introduccion {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 5px;
    animation: slideInUp 1s ease-out;
    margin-bottom: 50px;
  }
  
  .introduccion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .introduccion-header h1 {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin: 0;
  }
  
  .introduccion-header button {
    font-size: 24px;
    background-color: #333;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .introduccion-header button:hover {
    background-color: #fff;
    color: #333;
  }
  
  .nombre {
    font-size: 48px;
    font-weight: bold;
    color: #333;
    animation: fadeIn 1s ease-out;
  }
  
  .apellido {
    font-size: 36px;
    font-weight: normal;
    color: #555;
    animation: fadeIn 1s ease-out 0.5s;
  }
  
  .descripcion {
    font-size: 24px;
    font-weight: normal;
    color: #777;
    margin-top: 20px;
    animation: fadeIn 1s ease-out 1s;
    text-align: justify;
    line-height: 1.5;
  }
  
  @keyframes slideInUp {
    from {
      transform: translateY(50%);
      opacity: 0;
    }
  
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }