.Certifications {
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(2,0,36,1) 100%);
  display: flex;
  flex-direction: column;
  align-items: right;
  overflow: hidden;
  text-align: right;
  padding: 40px 20px;
}

.Certifications h1 {
  font-size: 36px;
  font-weight: bold;
  color: #4f4d4d;
  margin: 0;
  text-align: left;
}

.Certifications button {
  font-size: 24px;
  background-color: #151212;
  color: #fff;
  border: none;
  padding: 10px 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.Certifications button:hover {
  background-color: #fff;
  color: #333;
}

.Certifications ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  margin: 0;
  padding: 0;
}

.Certification {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 10px;
  transition: transform 0.3s ease-in-out;
}

.Certification:hover {
  transform: translateY(-5px);
}

.Certification img {
  width: 100%;
  height: 75%;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
}

.Certification h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.Certification h2 br {
  display: none;
}

.Certification h2 span {
  font-size: 16px;
  font-weight: normal;
  color: #888;
}

.Certification h4 {
  font-size: 16px;
  font-weight: normal;
  color: #333;
  margin: 10px 0 0 0;
}

.certificationButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 30px;
  margin-top: 20px;
  transition: all 0.3s ease;
}

.certificationButton:hover {
  background-color: #fff;
  color: #333;
  border: 2px solid #333;
}




 @media (max-width: 768px) {
 /* Para pantallas más pequeñas, se reduce el ancho de la certificación */
 .Certification {
  width: 40%;
 }
 }
 
 @media (max-width: 480px) {
 /* Para pantallas aún más pequeñas, se reduce aún más el ancho de la certificación */
 .Certification {
  width: 80%;
 }
 }
 

 @keyframes slideInUp {
 from {
  transform: translateY(50%);
  opacity: 0;
 }
 
 to {
  transform: translateY(0);
  opacity: 1;
 }
 }
 
 @keyframes fadeIn {
 from {
  opacity: 0;
 }
 
 to {
  opacity: 1;
 }
 }

 