.ContainerContact {
  display: flex;
  width: 100%;
}

.left,
.right {
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
}

.right img {
  max-width: 80%;
  max-height: 250px;
  height: auto;
  margin: 0 auto;
  display: block;
  transition: all 0.5s ease-in-out;
}

form {
  width: 100%;
}

.form-group input, 
.form-group textarea {
  width: 100%;
  box-sizing: border-box;
}

button[type="submit"] {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}
