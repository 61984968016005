/*.header {
    position: relative;
    width: 100%;
    height: 30vh;
  }
  
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .video-background video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .profile-image {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(0, -50%);
  }
  
  .profile-image img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 5px solid white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  }
  
  .text-wrapper {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(0, -50%);
    color: white;
  }
  
  .text-wrapper h1 {
    font-size: 2em;
    margin: 0;
  }
  
  .text-wrapper p {
    font-size: 1.2em;
    margin: 0;
  }
  */

  .header {
    position: relative;
    width: 100%;
    height: 30vh;
  }
  
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .video-background video {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .profile-image {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(0, -50%);
  }
  
  .profile-image img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 5px solid white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  }
  
  .text-wrapper {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(0, -50%);
    color: white;
  }
  
  .text-wrapper h1 {
    font-size: 2em;
    margin: 0;
  }
  
  .text-wrapper p {
    font-size: 1.2em;
    margin: 0;
  }
  
  /* Estilos para pantallas de 768px o más */
  @media screen and (min-width: 768px) {
    .profile-image {
      left: 10%;
    }
    .text-wrapper {
      left: 40%;
    }
  }
  
  /* Estilos para pantallas de 992px o más */
  @media screen and (min-width: 600px) {
    .text-wrapper {
      left: 50%;
    }
  }
  