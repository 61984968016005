.popup-overlay-course {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; /* se agrega overflow */
  padding: 1rem;
  overflow-y: auto;

  flex-direction: column;
  max-height: 85%; /* Establecemos la altura máxima */
  overflow-y: auto; /* Aparece la barra de desplazamiento solo si se excede el contenido */
  justify-content: space-between;
}
 

.popup-content-course {
  background-color: white;
  width: 85%;
  max-width: 85%;
  display: flex;
  flex-direction: column;
  overflow: auto; /* se agrega overflow */
  padding: 1rem;
  overflow-y: auto;

}
  .popup-content {
    background-color: white;
    width: 85%;
    max-width: 85%;
    display: flex;
    flex-direction: column; /* se agrega una columna */
    overflow: auto; /* se agrega overflow */
    overflow-y: auto;
  
  }
  
  .popup-left {
    flex: 0 0 45%;
    font-weight: bold;
  }
  
  .popup-right {
    flex: 1;
  }
  .popup-right p{
    text-align: justify;
    padding: 2%;
  }
  
  .popup-header {
    display: fixed;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    font-weight: bold; /* se agrega negrita */
    align-self: center; /* se centra */
    width: 100%; /* se extiende a lo ancho */
    text-align: center; /* se centra el texto */
    
    position: sticky;
    top: 0%;
    z-index: 1;
    background-color: white;
    
  }

  .popup-header h1{
    text-align: center; 
    align-items: center;
  }
  .popup-body {
    display: flex;
    padding: 1rem;
    justify-content: space-between;
  }
  
  .popup-body img {
    max-width: 100%;
  }